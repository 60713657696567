<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  props: ['visible'],
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      version: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('merchant', {
      hasDinein: 'hasDinein',
    }),
    currentUrl() {
      return `https://${window.location.host}`;
    },
    isSafari() {
      const userAgent = window.navigator.userAgent;
      return ("standalone" in window.navigator) && !window.navigator.standalone && /Safari/i.test(userAgent) && /iPhone|iPad|iPod/i.test(userAgent) && !/CriOS/i.test(userAgent);
    },
    show: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        if (!val) {
          this.$emit('close', false);
        }
      },
    },
  },
  methods: {
    ...mapActions('config', ['getAdminOption']),
    ...mapActions('merchant', [
      'getMerchantByHostname',
    ]),
    ...mapMutations('cart', ['SET_SERVICE']),
    close() {
      this.$router.push({ name: 'home' });
    },
  },
  async mounted() {
    this.$gtag.pageview(this.$route);

		// Get merchant profile
		await this.getMerchantByHostname(process.env.VUE_APP_DEFAULT_HOSTNAME || location.hostname);

    // if (!this.hasDinein) {
    //   this.$router.push({ name: 'home' });
    // }
  },
};
</script>
<template>
  <v-dialog content-class="page-install-pwa-ios" v-model="show" transition="dialog-bottom-transition">
    <v-card height="80dvh">
      <v-card-title class="h6 font-weight-6 tertiary--text">
        <v-icon class="mr-1" large>mdi-cellphone-arrow-down</v-icon>
        Install App Guidelines
      </v-card-title>
      <v-card-text>
        <ol>
          <li class="mt-1" v-if="!isSafari">Re-open in <v-icon class="mb-1" color="blue">mdi-apple-safari</v-icon> Safari.</li>
          <li
            :class="{
              'mt-1': isSafari,
              'mt-3': !isSafari
            }"
          >Tap <v-icon class="mb-1" color="blue">mdi-export-variant</v-icon> from the menu bar at the bottom and scroll down</li>
          <li class="mt-3">Tap <span class="mx-1 px-2 py-1 bg-dark white--text">Add to Home Screen <v-icon class="mb-1" color="white">mdi-plus-box-outline</v-icon></span></li>
          <li class="mt-3">Tap &lt;<span class="blue--text font-weight-bold">Add</span>&gt; at the top right.</li>
          <li class="mt-3">Now you may find our app at your Home Screen.</li>
        </ol>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.page-install-pwa-ios {
  height: 80dvh;
  border-radius: 20px;
  margin-top: -20px;
}

.page-install-pwa-ios .v-card {
  background-color: rgb(247, 239, 232) !important;
}
</style>
